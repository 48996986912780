import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import changeLogo from "../images/about/logo-change-writer.png"
import bwwwalchLogo from "../images/general/logos/bw-walch-logo.png"

const About = ({location}) => {
  return (
    <>
    <Helmet>
      <title>About Us</title>
    </Helmet>
    <Layout location={location}>
      
      <div className="container txt-center">
        
        <div className="aboutBanner">
          <h1 className="aboutTitle"><span className="standout">We've got a new look…</span><br/> GoWrite is now called BoomWriter UK</h1>
          <div className="aboutBanner_img">
            <img src={changeLogo} alt="GoWrite is now BoomWriter UK" />
          </div>
        </div>

        
        <div>
          <h2>It's the same app, just with a different name.</h2>
          <p className="headingSmall mb-2">You will continue to access BoomWriter UK in exactly the same way, with your same account.</p>
        </div>
        <div class="aboutDesc">
          <div className="aboutPanel">
            <h3 className="standout">What does this mean for you?</h3>
            <p>Other than getting familiar with the new name and logo… <strong>nothing!</strong></p>
            <p>Your accounts and your data are unaffected, we've just changed the name and the logo.</p>
            <p>No new accounts need to be created - you can login as normal and continue to write your books.</p>
          </div>
          <div className="aboutPanel">
            <h3 className="standout">Why has the name changed?</h3>
            <p>As a result of our parent company (Squirrel Learning Ltd) being acquired by BW Walch, we've joined forces with BoomWriter.</p>
            <p>This merger unlocks new investment so we can bring forward enhancements, new features and expand our team.</p>
            <p>BoomWriter UK (previously GoWrite) continues to be operated by Squirrel Learning Limited with no change to our terms, privacy policy or the way in which we safeguard and process your data.</p>
          </div>
        </div>

        <div className="mb-2 mt-2">
          <div className="aboutBWW">
            <img src={bwwwalchLogo} alt="BW Walch" />
          </div>
          <a href="https://bwwalch.com" className="inline-link">More about BW Walch</a>
        </div>

      </div>
    </Layout>
    </>
  )
}

export default About